import React, { forwardRef, useEffect, useState } from "react";
import { Button, Dialog, IconButton, Menu, MenuItem, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

import StudentReport from "./views/Facilitator/StudentReport";
import TransferStudent from "./views/Admin/TransferStudent";
import DeleteStudent from "./views/Admin/DeleteStudent";
import EditStudent from "./views/Admin/EditStudent";

function descendingComparator(a, b, orderByValue) {
  if (b[orderByValue] < a[orderByValue]) {
    return -1;
  }
  if (b[orderByValue] > a[orderByValue]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderByValue) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderByValue)
    : (a, b) => -descendingComparator(a, b, orderByValue)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Dialogue
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TableComponent = ({columns, orderBy, tableData, tableHeight, sort}) => {

  useEffect(() => {
    setPage(0)
  }, [tableData])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [order, setOrder] = useState(sort ? sort : 'asc');
  const [orderByValue, setOrderByValue] = useState(orderBy[0]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleRequestSort = (header) => {
    setOrder(orderByValue === header && order === 'asc' ? 'desc' : 'asc');
    setOrderByValue(header);
  };

  // Dialogue
  const [open, setOpen] = useState(false);
  const [dialogueData, setDialogueData] = useState("")
  const maxWidth = "lg"

  const handleClickOpen = (data) => {
    setOpen(true);
    setDialogueData(data)
  };

  const handleClose = () => {
    setOpen(false);
    setDialogueData("")       // comment this if you want to keep the loaded data after closing the dialogue
  };

  // options
  const [optionsData, setOptionsData] = useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOptions = Boolean(anchorEl);
  const handleClick = (event, optionsData) => {
    setAnchorEl(event.currentTarget);
    setOptionsData(optionsData)
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer {...tableHeight ? {sx: { maxHeight: tableHeight }} : {sx: { maxHeight: "57vh"}}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                key="srNo"
                align="center"
                style={{ minWidth: 20, padding: "12px" }}
              >
                <h4>Sr No.</h4>
              </TableCell>
              {columns.map((column) =>
                orderBy.includes(column.key) ? (
                  <TableCell
                    key={column.key}
                    align="center"
                    sortDirection={orderByValue === column.key ? order : false}
                    style={{ minWidth: column.width, padding: "12px" }}
                  >
                    <TableSortLabel
                      active={orderByValue === column.key}
                      direction={orderByValue === column.key ? order : 'asc'}
                      sx={{"& svg": {"opacity": 0.25}}}
                      onClick={() => handleRequestSort(column.key)}
                    >
                      <h4>{column.headerName}</h4>
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.key}
                    align="center"
                    style={{ minWidth: column.width, padding: "12px" }}
                  >
                    <h4>{column.headerName}</h4>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ? (
              stableSort(tableData, getComparator(order, orderByValue))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover tabIndex={-1} key={i}>
                      <TableCell
                        style={{ textAlign: "center", padding: "12px" }}
                      >
                        {i + 1 + page * rowsPerPage}
                      </TableCell>
                      {columns.map((column) =>
                        <TableCell
                          style={{ textAlign: "center", padding: "12px" }}
                          key={column.key}
                        >
                          {(!column.date && !column.percent && !column.tooltip && !column.dialogue && !column.options) && row[column.key]}
                          {column.date && (row[column.key] ? new Date(row[column.key]).toLocaleDateString("es-CL") : row[column.key])}
                          {column.percent && row[column.key] + "%"}
                          {column.tooltip && ((row[column.key] === "Invalid Attempt" || row[column.key] === "Audio Missing") ? 
                            <>
                              {row[column.key]} 
                                <Tooltip 
                                  enterTouchDelay={50} 
                                  arrow 
                                  title={row[column.key] === "Invalid Attempt" ? "WCPM cannot be calculated since student did not read atleast half of all paragraphs in the story or Audio was blank." : "WCPM cannot be calculated as test audios were deleted before uploading attempt."}>
                                    <IconButton sx={{padding: 0, color: "#0288D1"}}><InfoIcon/></IconButton>
                                </Tooltip> 
                            </> 
                            : row[column.key])
                          }
                          {(column.dialogue && column.dialogue === "report") &&
                            // <StudentReport testId={column.testId} studId={row[column.key]}/>
                            <Button 
                              size="small" 
                              disabled={!row[column.key]}
                              onClick={() => handleClickOpen({"dialogue": "report", "testId": column.testId, "studId": row["studId"]})} 
                              variant="contained" 
                              startIcon={<AssessmentIcon />} 
                            >
                              View Report
                            </Button>
                          }
                          {column.options &&
                            <IconButton
                              size="small"
                              aria-label="more"
                              id="long-button"
                              aria-controls={openOptions ? 'options-menu' : undefined}
                              aria-expanded={openOptions ? 'true' : undefined}
                              aria-haspopup="true"
                              sx={{padding: 0}}
                              onClick={(e) => handleClick(e, column.options==="students" && {
                                  data: {...row, "test": column.test}, 
                                  options: ["Edit Details", "Delete Student", "Transfer to Dropbox"],
                                  icons: [<EditIcon sx={{mr: 1, fontSize: "inherit"}}/>,<DeleteIcon sx={{mr: 1, fontSize: "inherit"}}/>,<ArchiveIcon sx={{mr: 1, fontSize: "inherit"}}/>]
                                })
                              }
                              disabled={column.options==="students" && row["rollNo"]===0}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow hover tabIndex={-1}>
                <TableCell colSpan={columns.length + 1} style={{ textAlign: "center" }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={(page > (tableData.length/rowsPerPage)) ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />

      {dialogueData && 
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          fullWidth={true}
          maxWidth={maxWidth}
          scroll="paper"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              backgroundColor: 'var(--new-bg-color)',
              borderRadius: '10px'
            }
          }}
        >
          {dialogueData.dialogue === "report" && 
            <StudentReport handleClose={handleClose} testId={dialogueData.testId} studId={dialogueData.studId}/>
          }
          {dialogueData.dialogue === "Edit Details" &&
            <EditStudent handleClose={handleClose} studentData={dialogueData.data}/>
          }
          {dialogueData.dialogue === "Delete Student" &&
            <DeleteStudent handleClose={handleClose} studentData={dialogueData.data}/>
          }
          {dialogueData.dialogue === "Transfer to Dropbox" &&
            <TransferStudent handleClose={handleClose} studentData={dialogueData.data}/>
          }
        </Dialog>
      }
      { optionsData &&
        <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openOptions}
        onClose={handleCloseOptions}
        slotProps={{
          paper: {
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              // width: '10ch',
            },
          },
        }}
      >
        {optionsData.options.map((option, i) => (
          <MenuItem key={option} onClick={() => {
              handleClickOpen({"dialogue": option, "data": optionsData.data}) 
              handleCloseOptions()
            }}
          >
            {optionsData.icons[i]}
            {option}
          </MenuItem>
        ))}
      </Menu>
      }
    </>
  );
};

export default TableComponent;
