import React, { forwardRef, useState } from 'react'
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, Slide, Snackbar } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteStudents({adminInfo, schoolId}) {
  const [open, setOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  // const [maxWidth, setMaxWidth] = useState("sm");
  const maxWidth = "sm"
  const [deleteState, setDeleteState] = useState(false);

  // useEffect(() => {
  //   if(window.screen.width<=500){
  //     setMaxWidth("sm")
  //   }
  // }, []);
  const deleteStudents = async (adminId, testId, schoolId) => {

    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;

      try {
        const deleteResponse =  await axios.delete("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/students?testId="+testId+"&schoolId="+schoolId, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("Upload Response: ",deleteResponse.data)
        if(deleteResponse.data === "Deletion Successful!") {
          alert("Deletion Successful: \n1. All student entries have been deleted. \n2. Please ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.");
          setToastType(["success", "Deleted All Entries Successfully!"])
          window.location.reload()
        }
        else if(deleteResponse.data === "Not Allowed to delete student entries when recordings exist in database."){
          alert("Deletion Aborted: \n1. Student recordings from your class have already been uploaded from the TARA App. \n2. You will not be able to delete any entries. \n3. Please fill up the form by clicking on \"Report Issue\" from the Navigation Bar on the left, if you still wish to delete all student entries. We will get in touch with you to assist with the same.")
          setToastType(["error", "Deletion Aborted!"])
        }
        else{
          alert("Deletion Cancelled: \n"+deleteResponse.data)
          setToastType(["error", deleteResponse.data])
        }
        setToastOpen(true)
        setDeleteState(false)
      }
      catch (error) {
        console.log(error)
        setToastType(["error", "Entries not Deleted due to Error!"])
        setToastOpen(true)
        setDeleteState(false)
      }
    })
    .catch((err) => console.log(err));
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {

    if(!adminInfo.test){
      alert("Test has not started yet")
      return
    }
    setDeleteState(true)
    deleteStudents(adminInfo.admin.adminId, adminInfo.test.testId, schoolId)
    // setOpen(false);
  }

  return (
    <div className="deleteStudents">
      <Button 
        onClick={handleClickOpen}
        variant="contained" color="error" startIcon={<DeleteIcon />} 
        sx={{ placeSelf: "start" }}
      >
        Delete All Entries
      </Button>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth}
        scroll="paper"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: 'var(--new-bg-color)',
            borderRadius: '10px'
          }
        }}
      >
        <DialogTitle sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
          <WarningIcon color="error" sx={{fontSize: "1.25rem"}}/>
          <b>Delete All Student Entries?</b>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            {(adminInfo.test ? adminInfo.test.status===0 : false) 
            ?
              <>
                <h4 style={{paddingBottom: "0.5rem"}}>Note:</h4>
                {/* <List dense disablePadding sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                  <ListItem disableGutters>
                    <ListItemText primary={<p>1. If any student recordings from your class have already been uploaded from the TARA App, you will not be able to delete any entries.</p>}/>
                  </ListItem>
                </List>
                <h4 style={{padding: "0.5rem 0"}}>If you still wish to proceed,</h4> */}
                <List dense disablePadding sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                  <ListItem disableGutters>
                    <ListItemText primary={<p>1. By proceeding, you will be deleting all the student entries from your school <b>"{adminInfo.admin.schools.filter(item => item.id === schoolId)[0].name}"</b>.</p>}/>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<p>2. You will need to upload the student Excel sheet with all the corrected student details again.</p>}/>
                  </ListItem>
                </List>
              </>
            :
              <Alert severity="error">
                <AlertTitle><b>Entries cannot be deleted after the Student Addition Phase.</b></AlertTitle>
              </Alert>
          }
        </DialogContent>
        <DialogActions>
          {(adminInfo.test ? adminInfo.test.status!==0 : false) 
          ? 
            <Button color="info" variant="contained" onClick={handleClose}>Close</Button>
          :
            <>
              <h4 style={{marginRight: "auto", paddingLeft: "1rem", fontSize: "14px"}}>Do you want to delete all student entries?</h4>
              <Button color="info" variant="contained" onClick={handleClose}>No, Cancel</Button>
              {deleteState 
                ?
                <Button color="error" variant="contained" disabled>Yes, Delete<CircularProgress size="1rem" color="inherit" sx={{marginLeft: "0.25rem"}}/></Button>
                :
                <Button color="error" variant="contained" onClick={handleDelete}>Yes, Delete</Button>
              }
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteStudents