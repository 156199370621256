import React from 'react'
import Navbar from './Navbar'
import { Button } from '@mui/material'

function ContactUs() {
  
  return (
    <>
      <Navbar header="Contact Us"/>
      <div className="mainContent">
        <div style={{display: "flex", alignItems: "center", justifyContent:"center", width: "100%"}}>
          <div className="contactUs">
            <h3>Contact Us: </h3>
            <p><b>Address: </b>Digital Audio Processing Lab,<br/>
              EE 119, First Floor, Old EE Building,<br/>
              Department of Electrical Engineering,<br/>
              Indian Institute of Technology Bombay,<br/>
              Powai, Mumbai - 400 076, India
            </p>
            {/* <h4>Phone: <span></span>
              <Button 
                variant="text" 
                href="tel:+91-022-25767695"
                sx={{padding: "0", textTransform: "lowercase", textDecoration: "underline", textUnderlinePosition: "under"}} 
              >+91-022-25767695</Button>
            </h4> */}
            <h4>Email: <span></span>
              <Button 
                variant="text" 
                href="mailto:prosical.iitb@gmail.com"
                sx={{padding: "0", textTransform: "lowercase", textDecoration: "underline", textUnderlinePosition: "under"}} 
              >prosical.iitb[AT]gmail.com</Button>
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs