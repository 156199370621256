import React, { useState, useEffect } from "react";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
// import MapIcon from '@mui/icons-material/Map';
// import SchoolIcon from '@mui/icons-material/School';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Table from "../../TableComponent";

const Facilitator = ({adminInfo}) => {
  const [facilitatorData, setFacilitatorData] = useState([])
  
  useEffect(() => {
    if(adminInfo.test){

      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getFacilitatorData = async(testId, adminId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/facilitator?testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Facilitator Data: ",response.data)
            // adding full name by combining firstName and lastName
            const facilitatorData = response.data["facilitator"].map(item => ({
              ...item, 
              "fullName": item["firstName"] + " " + item["lastName"],
              "classSection": item["class"] + "-" + item["section"],
              "enRecPercent": item["enRecCount"] ? Math.round(item["enRecCount"]*100/item["studentCount"]*100)/100 : 0, 
              "hiRecPercent": item["hiRecCount"] ? Math.round(item["hiRecCount"]*100/item["studentCount"]*100)/100 : 0,
              "enViewPercent": item["enViewCount"] ? Math.round(item["enViewCount"]*100/item["enFileCount"]*100)/100 : 0,
              "hiViewPercent": item["hiViewCount"] ? Math.round(item["hiViewCount"]*100/item["hiFileCount"]*100)/100 : 0
            }))
            setFacilitatorData(facilitatorData)
            // console.log(facilitatorData)
          }
          catch (error) {
              console.log(error)
          }
        }
        
        getFacilitatorData(adminInfo.test ? adminInfo.test.testId : null, adminInfo.admin.adminId)
        
      })
      .catch((err) => console.log(err));
    }
    else alert("Test has not started yet")
    
  }, [adminInfo]);
  
  const columnsEn = [
    {
      key: "fullName",
      headerName: "Teacher Name",
      width: 80
    },
    {
      key: "schoolName",
      headerName: "School Name",
      width: 80
    },
    { 
      key: "shift", 
      headerName: "Shift", 
      width: 20 
    },
    { 
      key: "region", 
      headerName: "Region", 
      width: 60 
    },
    // { 
    //   key: "class", 
    //   headerName: "Class", 
    //   width: 20 
    // },
    // { 
    //   key: "section", 
    //   headerName: "Section", 
    //   width: 20 
    // },
    { 
      key: "classSection", 
      headerName: "Class", 
      width: 20 
    },
    { 
      key: "studentCount", 
      headerName: "Students Count", 
      width: 40 
    },
    // {
    //   key: "enRecCount",
    //   headerName: "Students Tested Count - English",
    //   width: 40
    // },
    {
      key: "enRecPercent",
      headerName: "Students Tested - English (%)",
      width: 80,
      percent: true
    }
  ];

  // if(adminInfo?.test?.status === 3) columnsEn.push({
  //   key: "enViewPercent",
  //   headerName: "Intervention Material Viewed - English (%)",
  //   width: 40,
  //   percent: true
  // })

  const columnsHi = [
    {
      key: "fullName",
      headerName: "Teacher Name",
      width: 80
    },
    {
      key: "schoolName",
      headerName: "School Name",
      width: 80
    },
    { 
      key: "shift", 
      headerName: "Shift", 
      width: 20 
    },
    { 
      key: "region", 
      headerName: "Region", 
      width: 60 
    },
    // { 
    //   key: "class", 
    //   headerName: "Class", 
    //   width: 20 
    // },
    // { 
    //   key: "section", 
    //   headerName: "Section", 
    //   width: 20 
    // },
    { 
      key: "classSection", 
      headerName: "Class", 
      width: 20 
    },
    { 
      key: "studentCount", 
      headerName: "Students Count", 
      width: 40 
    },
    // {
    //   key: "hiRecCount",
    //   headerName: "Students Tested Count - Hindi",
    //   width: 40
    // },
    {
      key: "hiRecPercent",
      headerName: "Students Tested - Hindi (%)",
      width: 80,
      percent: true
    }
  ];

  // if(adminInfo?.test?.status === 3) columnsHi.push({
  //   key: "hiViewPercent",
  //   headerName: "Intervention Material Viewed - Hindi (%)",
  //   width: 40,
  //   percent: true
  // })

  const headers = [
    { label: "Teacher Name", key: "fullName" },
    { label: "School Name", key: "schoolName" },
    { label: "Shift", key: "shift" },
    { label: "Region", key: "region" },
    { label: "Class", key: "class" },
    { label: "Section", key: "section" },
    { label: "Students Count", key: "studentCount" },
    { label: "Students Tested Count - English", key: "enRecCount"},
    { label: "Students Tested - English (%)", key: "enRecPercent" },
    { label: "Students Tested Count - Hindi", key: "hiRecCount" },
    { label: "Students Tested - Hindi (%)", key: "hiRecPercent" },
    // { label: "Intervention Material File Count - English", key: "enFileCount" },
    // { label: "Intervention Material View Count - English", key: "enViewCount" },
    // { label: "Intervention Material Viewed - English (%)", key: "enViewPercent" },
    // { label: "Intervention Material File Count - Hindi", key: "hiFileCount" },
    // { label: "Intervention Material View Count - Hindi", key: "hiViewCount" },
    // { label: "Intervention Material Viewed - Hindi (%)", key: "hiViewPercent" }
  ];

  const orderByEn = ["enRecPercent", "enViewPercent", "studentCount"];
  const orderByHi = ["hiRecPercent", "hiViewPercent", "studentCount"];
  const [region, setRegion] = useState("");
  const [schoolName, setschoolName] = useState("");
  const [tabValue, setTabValue] = useState(0);
  
  const handleRegionChange = (e) => {
    if (e.target.value ==="" ) setschoolName("")
    setRegion(e.target.value);
  };
  
  const handleSchoolNameChange = (e) => {
    setschoolName(e.target.value);
  };
  
  // const handleRegionChipDelete = () => {
  //   setRegion("");
  //   setschoolName("");
  // };
  
  // const handleSchoolNameChipDelete = () => {
  //   setschoolName("");
  // };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  var filteredFacilitatorData
  var counts = {studentCount: 0, enRecCount: 0, hiRecCount: 0}
  if(region==="" || (region==="" && schoolName==="")){
    filteredFacilitatorData = facilitatorData
  }
  else if(region!=="" && schoolName===""){
    filteredFacilitatorData = [...facilitatorData.filter(item => item.region === region)]
  }
  else{
    filteredFacilitatorData = [...facilitatorData.filter(item => item.region === region && item.schoolName === schoolName)]
  }
  counts = filteredFacilitatorData.reduce((acc, item) => {
    return {
      studentCount: acc.studentCount + item.studentCount,
      enRecCount: acc.enRecCount + item.enRecCount,
      hiRecCount: acc.hiRecCount + item.hiRecCount
    }
  }, {studentCount: 0, enRecCount: 0, hiRecCount: 0})
  // console.log(counts)
  // console.log(facilitatorData)
  // console.log(filteredFacilitatorData)

  return (
    <div className="facilitatorContent innerContent">
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Facilitator Data{adminInfo.test ? <span> - {adminInfo.test.testName}</span>: ""}</h2>
      <div className="countTileContainer">
        <div className="countTile">
          <div className="countTileLeft">Total Students Count:</div>
          <div className="countTileRight">{counts.studentCount.toLocaleString("hi")}</div>
        </div>
        <div className="countTile">
          <div className="countTileLeft">Total Students Tested - English:</div>
          <div className="countTileRight">{counts.enRecCount.toLocaleString("hi")}</div>
        </div>
        <div className="countTile">
          <div className="countTileLeft">Total Students Tested - Hindi:</div>
          <div className="countTileRight">{counts.hiRecCount.toLocaleString("hi")}</div>
        </div>
      </div>
      <div className="filters">
        <div className="forms">
          <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
            <FilterListIcon fontSize="small"/>
            <span style={{height: "100%"}}>Filters:</span> 
          </h4>
          <FormControl sx={{ m: 1, minWidth: 150}} size="small">
            <InputLabel id="regionFilterLabel">Region</InputLabel>
            <Select
              labelId="regionFilterLabel"
              id="regionFilter"
              value={region}
              onChange={handleRegionChange}
              autoWidth
              label="Region"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique region values from data */}
              {[...new Set(facilitatorData.map(item => item.region))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="schoolNameFilterLabel">School Name</InputLabel>
            <Select
              labelId="schoolNameFilterLabel"
              id="schoolNameFilter"
              value={schoolName}
              onChange={handleSchoolNameChange}
              autoWidth
              label="School Name"
            >
              {region===""? 
                <MenuItem disabled>Select Region First</MenuItem>
                :
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              }
              {[...new Set(facilitatorData.filter(item => item.region === region).map(item => item.schoolName))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        {/* {(region || schoolName) &&
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            {region && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleRegionChipDelete} 
                icon={<MapIcon />}
                label={region}
                sx={{mr: 1}} 
              />
            }
            {schoolName &&
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleSchoolNameChipDelete} 
                icon={<SchoolIcon />}
                label={schoolName} 
              />
            }
          </div>
        } */}
      </div>
      <div className="tabs" style={{display: "flex"}}>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px", width: "60vw"}}>
          <Tab label="English" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          <Tab label="Hindi" sx={{fontSize: "inherit", minHeight: "30px"}}/>
        </Tabs>
        <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ marginLeft: "auto", placeSelf: "start" }}>
          <CSVLink data={facilitatorData} headers={headers} filename={"FacilitatorData.csv"}>
            Download as CSV
          </CSVLink>
        </Button>
      </div>
      <Divider />
      <div className="table">
        {tabValue===0 && 
          <Table
            columns={columnsEn}
            tableData={filteredFacilitatorData}
            orderBy={orderByEn}
            tableHeight="47vh"  
          />
        }
        {tabValue===1 && 
          <Table
            columns={columnsHi}
            tableData={filteredFacilitatorData}
            orderBy={orderByHi} 
            tableHeight="47vh" 
          />
        }
      </div>
    </div>
  );
};

export default Facilitator;
