import React, { useState } from 'react'
import { Alert, AlertTitle, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

function EditStudent({ handleClose, studentData }) {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [editForm, setEditForm] = useState({
    firstName: studentData.firstName, 
    lastName: studentData.lastName, 
    gender: studentData.gender,
    // std: studentData.std, 
    // divn: studentData.divn, 
    rollNo: studentData.rollNo
  });
  const [editFormError, setEditFormError] = useState({firstName: false, lastName: false, rollNo: false});
  const [editState, setEditState] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const handleEditFormChange = (field, value) => {
    // console.log(value)
    setEditForm(prev => ({...prev, [field]: value}))
    if(field === "firstName"){
      // (?!\s) to match any whitespace at beginning
      // This matches a-z and A-Z with space but not at starting and also rejects any whitespace character and allows 1 to 32 characters
      if((/^(?!\s)([A-Za-z ]){1,32}$/).test(value.trim())) setEditFormError(prev => ({...prev, [field]: false}))   
      else setEditFormError(prev => ({...prev, [field]: true}))
    }
    if(field === "lastName"){
      // This allows empty or matches a-z and A-Z with space but not at starting and also rejects any whitespace character and allows 1 to 32 characters
      if((/^$|^(?!\s)([A-Za-z ]){1,32}$/).test(value.trim())) setEditFormError(prev => ({...prev, [field]: false}))   
      else setEditFormError(prev => ({...prev, [field]: true}))
    }
    if(field === "rollNo"){
      if((/^(?!\s)([1-9]{1})$|^(?!\s)([1-9][0-9]{1})$/).test(value.trim())) setEditFormError(prev => ({...prev, [field]: false}))   
      else setEditFormError(prev => ({...prev, [field]: true}))
    }
  }

  const handleEdit = () => {
    if(!studentData.test){
      alert("Test has not started yet")
      return
    }

    const firstName = editForm.firstName.trim()
    const lastName = editForm.lastName.trim()
    const rollNo = String(editForm.rollNo).trim()
    const gender = editForm.gender.trim()

    if(!((/^(?!\s)([A-Za-z ]){1,32}$/).test(firstName) && (/^$|^(?!\s)([A-Za-z ]){1,32}$/).test(lastName) && (/^(?!\s)([1-9]{1})$|^(?!\s)([1-9][0-9]{1})$/).test(rollNo))) {
      setToastType(["warning", "Please fill all the required fields!"])
      setToastOpen(true)
      return
    }
    if(studentData.firstName === firstName && studentData.lastName === lastName && studentData.rollNo === Number(rollNo) && studentData.gender === gender) {
      setToastType(["warning", "Please change atleast 1 field to proceed!"])
      setToastOpen(true)
      return
    }
    setEditState(true)

    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;
      const adminId = data.idToken.payload["custom:db_id"]

      try {
        const editResponse =  await axios.put("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/students", 
          JSON.stringify({
            "testId": studentData.test.testId,
            "studId": studentData.studId,
            "firstName": firstName,
            "lastName": lastName,
            "rollNo": rollNo,
            "gender": gender
          }),
          {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("Edit Response: ",editResponse.data)
        if(editResponse.data.message === "Edit Successful!") {
          alert("Edit Successful: \n1. Student entry has been edited. \n2. Please ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.");
          setToastType(["success", "Edit Student Entry Successfully!"])
          window.location.reload()
        }
        else{
          alert("Edit Cancelled: \n"+editResponse.data.message)
          setToastType(["error", editResponse.data.message])
        }
        setToastOpen(true)
        setEditState(false)
      }
      catch (error) {
        if ([404, 422].includes(error.response.status)) setToastType(["error", error.response.data.message])
        else{
          console.log(error)
          setToastType(["error", "Entry not edited due to Error!"])
        }
        setToastOpen(true)
        setEditState(false)
      }
    })
    .catch((err) => console.log(err));
  }

  return (
    <>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
        <EditIcon color="info" sx={{fontSize: "1.25rem"}}/>
        <b>Edit Student Entry</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className='editStudent' style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
          {(studentData.test ? studentData.test.status===0 : false) 
            ?
              <>
                <div className="editStudentDetails" style={{display: "flex", gap:"1rem", backgroundColor: "#F1F3F9", borderRadius: "10px", padding: "1rem"}}>
                  <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                    <h4>Student Basic Details:</h4>
                    <p style={{marginBottom: "0.5rem"}}>PEN No: <span style={{fontWeight: 600}}>{studentData.studId}</span></p>
                    {/* <p>Student Name: {studentData.fullName}</p> */}
                    {/* <p>Gender: {studentData.gender}</p> */}
                    <div style={{display: "flex", flexWrap: "wrap", gap: "1rem"}}>
                      <FormControl sx={{ minWidth: 150}} size="small">
                        <TextField
                          error = {editFormError.firstName} 
                          helperText = {editFormError.firstName ? "Name must contain only letters and space & upto 32 characters. Leading Spaces are not allowed." : false} 
                          required
                          // margin="dense"
                          id="firstName"
                          name="First Name"
                          label="First Name"
                          // fullWidth
                          variant="outlined"
                          size="small"
                          value={editForm.firstName}
                          onChange={(e) => handleEditFormChange("firstName", e.target.value)}
                        />
                      </FormControl>
                      <FormControl sx={{ minWidth: 150}} size="small">
                        <TextField
                          error = {editFormError.lastName} 
                          helperText = {editFormError.lastName ? "Name must contain only letters and space & upto 32 characters. Leading Spaces are not allowed." : false}
                          // margin="dense"
                          id="lastName"
                          name="Last Name"
                          label="Last Name"
                          // fullWidth
                          variant="outlined"
                          size="small"
                          value={editForm.lastName}
                          onChange={(e) => handleEditFormChange("lastName", e.target.value)}
                        />
                      </FormControl>
                      <FormControl sx={{minWidth: 150}} size="small">
                        <InputLabel id="genderFilterLabel">Gender</InputLabel>
                        <Select
                          labelId="genderFilterLabel"
                          id="genderFilter"
                          value={editForm.gender}
                          onChange={(e) => handleEditFormChange("gender", e.target.value)}
                          autoWidth
                          label="Gender"
                        >
                          {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                          <MenuItem value="M">Male</MenuItem>
                          <MenuItem value="F">Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Divider orientation="vertical" flexItem/>
                  <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                    <h4>Class Details:</h4>
                    <div style={{display: "flex", flexWrap: "wrap", gap: "1rem", marginBottom: "0.5rem"}}>
                      <p>Class: <span style={{fontWeight: 600}}>{studentData.std}</span></p>
                      <p>Section: <span style={{fontWeight: 600}}>{studentData.divn}</span></p>
                    </div>
                    {/* <p>Roll No: {studentData.rollNo}</p> */}
                    <div style={{display: "flex", flexWrap: "wrap", gap: "1rem"}}>
                      {/* <FormControl sx={{minWidth: 150}} size="small">
                        <InputLabel id="stdFilterLabel">Class</InputLabel>
                        <Select
                          labelId="stdFilterLabel"
                          id="stdFilter"
                          value={editForm.std}
                          onChange={(e) => handleEditFormChange("std", e.target.value)}
                          autoWidth
                          label="Class"
                        >
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ minWidth: 150}} size="small">
                        <InputLabel id="divnFilterLabel">Section</InputLabel>
                        <Select
                          labelId="divnFilterLabel"
                          id="divnFilter"
                          value={editForm.divn}
                          onChange={(e) => handleEditFormChange("divn", e.target.value)}
                          autoWidth
                          label="Section"
                        >
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="C">C</MenuItem>
                          <MenuItem value="D">D</MenuItem>
                          <MenuItem value="E">E</MenuItem>
                          <MenuItem value="F">F</MenuItem>
                          <MenuItem value="G">G</MenuItem>
                          <MenuItem value="H">H</MenuItem>
                          <MenuItem value="I">I</MenuItem>
                        </Select>
                      </FormControl> */}
                      <FormControl sx={{ minWidth: 150}} size="small">
                        <TextField
                          error = {editFormError.rollNo} 
                          helperText = {editFormError.rollNo ? "Roll No must be between 1 and 99." : false} 
                          required
                          // margin="dense"
                          id="rollNo"
                          name="Roll No"
                          label="Roll No"
                          // fullWidth
                          variant="outlined"
                          size="small"
                          value={editForm.rollNo}
                          onChange={(e) => handleEditFormChange("rollNo", e.target.value)}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 style={{paddingBottom: "0.5rem"}}>Note:</h4>
                  <List dense disablePadding sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                    <ListItem disableGutters>
                      <ListItemText primary={<p>1. By proceeding, you will be editing the student entry which will overwrite the existing details.</p>}/>
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary={<p>2. Please ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.</p>}/>
                    </ListItem>
                  </List>
                </div>
              </>
            :
              <Alert severity="error">
                <AlertTitle><b>Student entry cannot be edited after the Student Addition Phase.</b></AlertTitle>
              </Alert>
          }
        </div>
      </DialogContent>
      <DialogActions>
        {(studentData.test ? studentData.test.status===0 : false) 
        ? 
        <>
            <h4 style={{marginRight: "auto", paddingLeft: "1rem", fontSize: "14px"}}>Do you want to edit the student entry?</h4>
            <Button color="info" variant="contained" onClick={handleClose}>No, Cancel</Button>
            {editState 
              ?
              <Button color="warning" variant="contained" disabled>Yes, Edit<CircularProgress size="1rem" color="inherit" sx={{marginLeft: "0.25rem"}}/></Button>
              :
              <Button color="warning" variant="contained" onClick={handleEdit}>Yes, Edit</Button>
            }
          </>
        :
          <Button color="info" variant="contained" onClick={handleClose}>Close</Button>
        }
      </DialogActions>
    </>
  )
}

export default EditStudent