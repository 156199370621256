import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";
import FilterListIcon from '@mui/icons-material/FilterList';
// import TranslateIcon from '@mui/icons-material/Translate';
// import MapIcon from '@mui/icons-material/Map';
// import PinIcon from '@mui/icons-material/Pin';
// import EditNoteIcon from '@mui/icons-material/EditNote';

import BarChart from '../../BarChart';

const Report = ({ superAdminInfo }) => {
  const [reportData, setReportData] = useState("")
  const [testId, setTestId] = useState(superAdminInfo.allTests ? superAdminInfo.allTests[0].testId : null)
  
  useEffect(() => {
    if(superAdminInfo.allTests){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getReportData = async(superAdminId, testId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/superAdmin/"+superAdminId+"/report?testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Report Data: ",response.data["report"])
            setReportData(response.data["report"])
            setStd(response.data["report"]["allSchools"].map(item => item.std).sort()[0] || "")
            // setRegion([...new Set(response.data["report"]["allSchools"].map((item) => item.region))][0] || "")
          }
          catch (error) {
              console.log(error)
          }
        }
  
        getReportData(superAdminInfo.superAdmin.superAdminId, testId)
        
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [superAdminInfo, testId]);

  const [tabValue, setTabValue] = useState(0);
  const [std, setStd] = useState("");
  // hard coded lang to hindi on testId 5
  const [lang, setLang] = useState(testId!==5 ? "English" : "Hindi");
  const [region, setRegion] = useState("");

  const handleTabChange = (event, newValue) => {
    if(newValue === 1 && ["ZIET BHUBANESWAR", "ZIET CHANDIGARH", "ZIET GWALIOR", "ZIET MUMBAI", "ZIET MYSORE"].includes(region)) setRegion("")
    setTabValue(newValue);
    // console.log(event.target.firstChild.data)
    ReactGA.event({
      category: 'Super Admin Results Tab',
      action: 'Result Tab Switch',
      label: event.target.firstChild.data
    });
  };

  const handleTestIdChange = (e) => {
    setStd("");
    setRegion("");
    setReportData("");
    setTestId(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  // const handleRegionChipDelete = () => {
  //   setRegion("");
  // };

  // harcoded benchmarks for rajashthan testId = 5
  const benchmarks = {
    2: [1, 1, 1, 1, 1, 5],
    3: [2332, 6652, 17141, 14042, 37616, 77783],
    4: [23172, 23575, 22766, 24762, 0, 94275],
    5: [24750, 23515, 25840, 24956, 0, 99061],
    6: [24062, 24094, 23523, 25629, 0, 97308],
    7: [24900, 26431, 25925, 25798, 0, 103054],
    8: [25369, 25953, 24735, 26813, 0, 102870]
  }

  var filteredReportData = reportData
  if(std!== "") filteredReportData = {
    "allSchools": reportData["allSchools"].filter(item => item.std === std),
    "thresholds": reportData["thresholds"].filter(item => item.std === std)[0],
    // harcoded benchmarks for rajashthan testId = 5
    "benchmarks": benchmarks[std]
  }

  return (
    <div className='report superAdminReport innerContent'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Results</h2>
      <div className="chartArea">
        <div className="topTabs">
          <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}}>
            <Tab label="Region Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            <Tab label="School Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          </Tabs>
          <div style={{display: "flex", flexDirection: "column", paddingTop: "0.5rem"}}>
            <h4 style={{color: "#dc3545"}}>Note: Invalid attempts are not considered in the results.</h4>
            <p style={{fontSize: "14px", color: "#dc3545"}}>
              Results generated based on recordings received till {superAdminInfo.allTests && 
                new Date(superAdminInfo.allTests.filter(item=> item.testId === testId)[0].uploadEndDate).toLocaleDateString("en-GB", { dateStyle: "long" })}
            </p>
          </div>
        </div>
        <div className="filters">
          <div className="forms">
            <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
              <FilterListIcon fontSize="small"/>
              <span style={{height: "100%"}}>Filters:</span> 
            </h4>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
              <Select
                labelId="testIdFilterLabel"
                id="testIdFilter"
                value={testId ? testId : ""}
                onChange={handleTestIdChange}
                autoWidth
                label="Test Name"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                {superAdminInfo.allTests &&
                  superAdminInfo.allTests.map(item => item.testName).map((item, i) => {
                  return <MenuItem key={i} value={superAdminInfo.allTests[i].testId}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="langFilterLabel">Language</InputLabel>
              <Select
                labelId="langFilterLabel"
                id="langFilter"
                value={lang}
                onChange={handleLangChange}
                autoWidth
                label="Language"
              >
                {/* hard coded lang to hindi on testId 5 */}
                {testId!==5 && <MenuItem value="English">English</MenuItem>}   
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 85}} size="small">
              <InputLabel id="stdFilterLabel">Class</InputLabel>
              <Select
                labelId="stdFilterLabel"
                id="stdFilter"
                value={std}
                onChange={handleStdChange}
                autoWidth
                label="Class"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                { reportData &&
                  [...new Set(reportData["allSchools"].map(item => item.std))].sort().map((item, i) => {
                  return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="regionFilterLabel">Region</InputLabel>
              <Select
                labelId="regionFilterLabel"
                id="regionFilter"
                value={region}
                onChange={handleRegionChange}
                autoWidth
                label="Region"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* getting all unique region values from data */}
                { reportData && 
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))].map((item, i) => {
                    return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
                {/* hard coded values for ziets */}
                {(reportData && superAdminInfo.superAdmin.superAdminId === 24 && tabValue === 0) &&
                  [
                    <MenuItem key={1} value="ZIET BHUBANESWAR">ZIET BHUBANESWAR</MenuItem>,
                    <MenuItem key={2} value="ZIET CHANDIGARH">ZIET CHANDIGARH</MenuItem>,
                    <MenuItem key={3} value="ZIET GWALIOR">ZIET GWALIOR</MenuItem>,
                    <MenuItem key={4} value="ZIET MUMBAI">ZIET MUMBAI</MenuItem>,
                    <MenuItem key={5} value="ZIET MYSORE">ZIET MYSORE</MenuItem>
                  ]
                }
                {/* hard coded values for ziets */}
              </Select>
            </FormControl>
          </div>
          {/* <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            { superAdminInfo.allTests && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleLangChipDelete} 
                icon={<EditNoteIcon />}
                label={superAdminInfo.allTests.filter(item=> item.testId === testId)[0].testName}
                sx={{maxWidth: 150}} 
              />
            }
            <Chip 
              variant="outlined" 
              color="info"  
              // onDelete={handleLangChipDelete} 
              icon={<TranslateIcon />}
              label={lang}
              // sx={{mr: 1}} 
            />
            {std && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleStdChipDelete} 
                icon={<PinIcon />}
                label={std}
              />
            }
            {region && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleRegionChipDelete} 
                icon={<MapIcon />}
                label={region}
                sx={{mr: 1}} 
              />
            }
          </div> */}
        </div>
        <div className="charts">
          {(reportData && tabValue === 0) &&
            <BarChart
              // harcoded benchmarks for rajashthan testId = 5
              chartData = {testId!==5 
                ? [filteredReportData["allSchools"].reduce((acc, item) => {
                  return lang === "English" ? 
                    [ acc[0] + item.enWcpmCat1, acc[1] + item.enWcpmCat2, acc[2] + item.enWcpmCat3, acc[3] + item.enWcpmCat4, acc[4] + item.enWcpmCat5, acc[5] + item.enTotal ]
                  :
                    [ acc[0] + item.hiWcpmCat1, acc[1] + item.hiWcpmCat2, acc[2] + item.hiWcpmCat3, acc[3] + item.hiWcpmCat4, acc[4] + item.hiWcpmCat5, acc[5] + item.hiTotal ]
                }, [0, 0, 0, 0, 0, 0])].concat(
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                    .filter(r => {
                      return region==="" ? true 
                      : region === "ZIET BHUBANESWAR" ? ["BHUBANESWAR", "GUWAHATI", "KOLKATA", "RANCHI", "SILCHAR", "TINSUKIA"].includes(r)
                      : region === "ZIET CHANDIGARH" ? ["CHANDIGARH", "DEHRADUN", "DELHI", "GURUGRAM", "JAMMU"].includes(r) 
                      : region === "ZIET GWALIOR" ? ["AGRA", "BHOPAL", "JABALPUR", "LUCKNOW", "VARANASI"].includes(r)
                      : region === "ZIET MUMBAI" ? ["AHMEDABAD", "JAIPUR", "MUMBAI", "PATNA", "RAIPUR"].includes(r)
                      : region === "ZIET MYSORE" ? ["BENGALURU", "CHENNAI", "ERNAKULAM", "HYDERABAD"].includes(r)
                      : region === r
                    })
                    .map(region => filteredReportData["allSchools"].filter(item=> item.region === region)
                    .reduce((acc, item) => {
                      return lang === "English" ? 
                        [ acc[0] + item.enWcpmCat1, acc[1] + item.enWcpmCat2, acc[2] + item.enWcpmCat3, acc[3] + item.enWcpmCat4, acc[4] + item.enWcpmCat5, acc[5] + item.enTotal ]
                      :
                        [ acc[0] + item.hiWcpmCat1, acc[1] + item.hiWcpmCat2, acc[2] + item.hiWcpmCat3, acc[3] + item.hiWcpmCat4, acc[4] + item.hiWcpmCat5, acc[5] + item.hiTotal ]
                    }, [0, 0, 0, 0, 0, 0]))
                  )
                :
                [filteredReportData["benchmarks"]].concat([filteredReportData["allSchools"].reduce((acc, item) => {
                  return lang === "English" ? 
                    [ acc[0] + item.enWcpmCat1, acc[1] + item.enWcpmCat2, acc[2] + item.enWcpmCat3, acc[3] + item.enWcpmCat4, acc[4] + item.enWcpmCat5, acc[5] + item.enTotal ]
                  :
                    [ acc[0] + item.hiWcpmCat1, acc[1] + item.hiWcpmCat2, acc[2] + item.hiWcpmCat3, acc[3] + item.hiWcpmCat4, acc[4] + item.hiWcpmCat5, acc[5] + item.hiTotal ]
                }, [0, 0, 0, 0, 0, 0])].concat(
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                    .filter(r => {
                      return region==="" ? true 
                      : region === "ZIET BHUBANESWAR" ? ["BHUBANESWAR", "GUWAHATI", "KOLKATA", "RANCHI", "SILCHAR", "TINSUKIA"].includes(r)
                      : region === "ZIET CHANDIGARH" ? ["CHANDIGARH", "DEHRADUN", "DELHI", "GURUGRAM", "JAMMU"].includes(r) 
                      : region === "ZIET GWALIOR" ? ["AGRA", "BHOPAL", "JABALPUR", "LUCKNOW", "VARANASI"].includes(r)
                      : region === "ZIET MUMBAI" ? ["AHMEDABAD", "JAIPUR", "MUMBAI", "PATNA", "RAIPUR"].includes(r)
                      : region === "ZIET MYSORE" ? ["BENGALURU", "CHENNAI", "ERNAKULAM", "HYDERABAD"].includes(r)
                      : region === r
                    })
                    .map(region => filteredReportData["allSchools"].filter(item=> item.region === region)
                    .reduce((acc, item) => {
                      return lang === "English" ? 
                        [ acc[0] + item.enWcpmCat1, acc[1] + item.enWcpmCat2, acc[2] + item.enWcpmCat3, acc[3] + item.enWcpmCat4, acc[4] + item.enWcpmCat5, acc[5] + item.enTotal ]
                      :
                        [ acc[0] + item.hiWcpmCat1, acc[1] + item.hiWcpmCat2, acc[2] + item.hiWcpmCat3, acc[3] + item.hiWcpmCat4, acc[4] + item.hiWcpmCat5, acc[5] + item.hiTotal ]
                    }, [0, 0, 0, 0, 0, 0]))
                  ))
              }
              // harcoded benchmarks for rajashthan testId = 5
              labelData = {testId!==5 
                ? ["OVERALL"].concat( 
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                    .filter(r => {
                      return region==="" ? true 
                        : region === "ZIET BHUBANESWAR" ? ["BHUBANESWAR", "GUWAHATI", "KOLKATA", "RANCHI", "SILCHAR", "TINSUKIA"].includes(r)
                        : region === "ZIET CHANDIGARH" ? ["CHANDIGARH", "DEHRADUN", "DELHI", "GURUGRAM", "JAMMU"].includes(r) 
                        : region === "ZIET GWALIOR" ? ["AGRA", "BHOPAL", "JABALPUR", "LUCKNOW", "VARANASI"].includes(r)
                        : region === "ZIET MUMBAI" ? ["AHMEDABAD", "JAIPUR", "MUMBAI", "PATNA", "RAIPUR"].includes(r)
                        : region === "ZIET MYSORE" ? ["BENGALURU", "CHENNAI", "ERNAKULAM", "HYDERABAD"].includes(r)
                        : region === r
                    })
                )
                : ["BENCHMARKS","OVERALL"].concat( 
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                    .filter(r => {
                      return region==="" ? true 
                        : region === "ZIET BHUBANESWAR" ? ["BHUBANESWAR", "GUWAHATI", "KOLKATA", "RANCHI", "SILCHAR", "TINSUKIA"].includes(r)
                        : region === "ZIET CHANDIGARH" ? ["CHANDIGARH", "DEHRADUN", "DELHI", "GURUGRAM", "JAMMU"].includes(r) 
                        : region === "ZIET GWALIOR" ? ["AGRA", "BHOPAL", "JABALPUR", "LUCKNOW", "VARANASI"].includes(r)
                        : region === "ZIET MUMBAI" ? ["AHMEDABAD", "JAIPUR", "MUMBAI", "PATNA", "RAIPUR"].includes(r)
                        : region === "ZIET MYSORE" ? ["BENGALURU", "CHENNAI", "ERNAKULAM", "HYDERABAD"].includes(r)
                        : region === r
                    })
                )
              }
              catLabelData={lang === "English" ? 
                  [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
                :
                  [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
              }
              title = {"WCPM - Region Wise - " + lang}
              xLabel = {region==="" ? "Regions" : "Region - " + region}
              yLabel = "School %"
              chartName={region}
            />
          }
          {(reportData && tabValue === 1 && region !=="") ? 
            <BarChart 
              chartData = {lang === "English" ? 
                            filteredReportData["allSchools"].filter(item => item.region === region)
                              .map(item => [item.enWcpmCat1, item.enWcpmCat2, item.enWcpmCat3, item.enWcpmCat4, item.enWcpmCat5, item.enTotal])
                            :
                            filteredReportData["allSchools"].filter(item => item.region === region)
                              .map(item => [item.hiWcpmCat1, item.hiWcpmCat2, item.hiWcpmCat3, item.hiWcpmCat4, item.hiWcpmCat5, item.hiTotal])
                          }
              labelData = {filteredReportData["allSchools"].filter(item => item.region === region).map(data => data.schoolName)}
              catLabelData={lang === "English" ? 
                [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
              :
                [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
              }
              title = {"WCPM - School Wise - " + lang}
              xLabel = {"Schools - " + region}
              yLabel = "Student %"
              chartName = {region}
            /> 
          : (reportData && tabValue === 1) &&
            <h3>Select a Region First</h3>
          }
          {!reportData && <CircularProgress color="info"/> }
        </div>
      </div>
    </div>
  )
}

export default Report