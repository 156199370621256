import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material'
import MapIcon from "@mui/icons-material/Map";
import SchoolIcon from "@mui/icons-material/School";
import AssessmentIcon from '@mui/icons-material/Assessment';

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Dashboard</h2>
      <div className='linkButtons' style={{display: "flex", flexWrap:"wrap", gap: "1rem", paddingTop: "1rem"}}>
        <Button variant='contained' color="info" startIcon={<MapIcon />} component={Link} to="/region">
          Monitor Region
        </Button>
        <Button variant='contained' color="info" startIcon={<SchoolIcon />} component={Link} to="/school">
          Monitor School
        </Button>
        <Button variant='contained' color="info" startIcon={<AssessmentIcon />} component={Link} to="/results">
          View Results
        </Button>
      </div>
    </div>
  )
}

export default Dashboard