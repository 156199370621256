import React, { useState } from 'react'
import axios from "axios";
import { Auth } from "aws-amplify";
import { AlertTitle, Button, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const TransferStudent = ( {handleClose, studentData} ) => {
  // console.log(studentData)
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [transferState, setTransferState] = useState(false)

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const transferStudent = async (studentData, status) => {

    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;
      const adminId = data.idToken.payload["custom:db_id"]

      try {
        const transferResponse =  await axios.post("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/dropbox-export", 
          JSON.stringify({
            "testId": studentData.test.testId,
            "studId": studentData.studId,
            "classId": studentData.classId,
            "schoolId": studentData.schoolId,
            "status": status
          }), 
          {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("Transfer Response: ",transferResponse.data)
        if(transferResponse.data.status === "success") {
          alert("Dropbox Transfer Successful!: \nPlease ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.");
          setToastType(["success", "Dropbox Transfer Successful!"])
          window.location.reload()
        }
        else{
          alert("Transfer Cancelled: \n"+transferResponse.data.message)
          setToastType(["error", transferResponse.data.message])
        }
        setToastOpen(true)
        setTransferState(false)
      }
      catch (error) {
        console.log(error)
        setToastType(["error", "Transfer cancelled due to error!"])
        setToastOpen(true)
        setTransferState(false)
      }
    })
    .catch((err) => console.log(err));
  };

  const handleTransfer = (status) => {
    if(!studentData.test){
      alert("Test has not started yet")
      return
    }

    setTransferState(true)
    transferStudent(studentData, status)
  }

  return (
    <>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{display: "flex", alignItems: "center", gap: "0.5rem", flexWrap: "wrap"}}>
        <ArchiveIcon color="info" sx={{fontSize: "1.25rem"}}/>
        <b>Transfer Student to Dropbox</b>
        {/* {reportJson && 
          <p style={{fontWeight: "bold", fontSize: "0.67em", marginLeft: "auto", marginRight: "20px"}}>
            {reportJson.studentDetails.schoolName + " Shift " + reportJson.studentDetails.shift + ", " + reportJson.studentDetails.region}
          </p>
        } */}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className='transferStudent' style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
          {(studentData.test ? studentData.test.status === 1 : false)
          ? 
            <>
              <MuiAlert severity="info" className="uploadInstructions">
                <AlertTitle><b>Dropbox Instructions:</b></AlertTitle>
                  <Divider/>
                  <List dense={true} sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                    <ListItem disableGutters>
                      <ListItemText primary={<p><strong>Left School with TC: </strong> This option needs to be selected when the student is transferred to another KVS school with TC.</p>}/>
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary={<p><strong>Mark Long Absentee: </strong> This option needs to be selected when the student is a long absentee.</p>}/>
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary={<p><strong>Note: </strong> For all the other cases kindly delete the student entry during the student addition phase.</p>}/>
                    </ListItem>
                  </List>
              </MuiAlert>
              <div className="transferStudentDetails" style={{display: "flex", gap:"1rem", backgroundColor: "#F1F3F9", borderRadius: "10px", padding: "1rem"}}>
                <div style={{display: "flex", flexDirection: "column", flex: "0.7", gap: "0.5rem"}}>
                  <h4>Student Basic Details:</h4>
                  <p>Student Name: {studentData.fullName}</p>
                  <p>Gender: {studentData.gender}</p>
                  <p>PEN No: {studentData.studId}</p>
                </div>
                <Divider orientation="vertical" flexItem/>
                <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                  <h4>Class Details:</h4>
                  <p>Class: {studentData.std}</p>
                  <p>Section: {studentData.divn}</p>
                  <p>Roll No: {studentData.rollNo}</p>
                </div>
              </div>
              <div style={{display: "flex", flexWrap: "wrap", gap: "1rem"}}>
                <Button color="primary" variant="contained" disabled={transferState} onClick={() => handleTransfer(0)}>Left School with TC</Button>
                <Button color="warning" variant="contained" disabled={transferState} onClick={() => handleTransfer(1)}>Mark Long Absentee</Button>
              </div>
            </>
          :
            <MuiAlert severity="error">
              <AlertTitle><b>Dropbox is only available during the Testing Phase.</b></AlertTitle>
            </MuiAlert>
          }
        </div>
      </DialogContent>
    </>
  )
}

export default TransferStudent