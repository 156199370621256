import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Button } from "@mui/material";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const BarChart = ({chartData, labelData, catLabelData, title, xLabel, yLabel, chartName}) => {
  let barChartRef = useRef(null)

  function downloadAsImage(){
    const link = document.createElement('a');
    link.download = (chartName ? title + " - " + chartName + "-Chart.png" : title + "-Chart.png");
    link.href = barChartRef.current.toBase64Image('image/png', 1);
    link.click();
  }

  const chartDataFormat = {
    labels: labelData, 
    datasets: [
      {
        label: catLabelData ? catLabelData[0] < 0 ? "Invalid Entries" : "WCPM between 0 - " + catLabelData[0] : "cat1",
        data: chartData.map((data) => data[0]/data[5]),
        backgroundColor: ["#FFB5B5"],
        // borderColor: "black",
        // borderWidth: 1
      },
      {
        label: catLabelData ? "WCPM between " + (catLabelData[0]+1) + " - " + catLabelData[1] : "cat2",
        data: chartData.map((data) => data[1]/data[5]),
        backgroundColor: ["#E8CFFA"],
        // borderColor: "black",
        // borderWidth: 1
      },
      {
        label: catLabelData ? "WCPM between " + (catLabelData[1]+1) + " - " + catLabelData[2] : "cat3",
        data: chartData.map((data) => data[2]/data[5]),
        backgroundColor: ["#8CCDFF"],
        // borderColor: "black",
        // borderWidth: 1
      },
      {
        label: catLabelData ? catLabelData[3] < 0 ?  "WCPM >= " + (catLabelData[2]+1) : "WCPM between " + (catLabelData[2]+1) + " - " + catLabelData[3] : "cat4",
        data: chartData.map((data) => data[3]/data[5]),
        backgroundColor: ["#AAEACB"],
        // borderColor: "black",
        // borderWidth: 1
      },
      {
        label: catLabelData ? catLabelData[3] < 0 ? "-" : "WCPM >= " + (catLabelData[3]+1) : "cat5",
        data: chartData.map((data) => data[4]/data[5]),
        backgroundColor: ["#E1F886"],
        // borderColor: "black",
        // borderWidth: 1
      }
    ]
  };

  return (
    <>
      <Button color="info" variant="contained" size="small" onClick={downloadAsImage} sx={{ml: "auto"}}>
        Download as Image
      </Button>
      <Bar
        ref = {barChartRef}
        data = {chartDataFormat}
        options = {
          {
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              title: {
                display: true,
                text: title,
                font: {
                  family: "Poppins",
                  size: "14px",
                },
              },
              datalabels: {
                color: "black",
                formatter: function (value, context) {
                  if (context.chart.data.datasets[context.datasetIndex] != null) {
                    if (value === 0) return null;
                    return chartData[context.dataIndex][context.datasetIndex]
                  }
                },
                events: [],
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.dataset.label + ": " + chartData[context.dataIndex][context.datasetIndex] + " (" + context.formattedValue + ")"
                  },
                  footer: function (tooltipItems) {
                    let total = 0;
                    tooltipItems.forEach(function(tooltipItem) {
                      total += chartData[tooltipItem.dataIndex][tooltipItem.datasetIndex];
                    });
                    return 'Total: ' + total;
                  },
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
                title: {
                  display: true,
                  text: xLabel,
                  font: {
                    family: "Poppins",
                    weight: "bold",
                    size: "14px",
                  },
                },
                ticks: {
                  // maxRotation: 0,   // This controls the rotation of labels
                }
              },
              y: {
                max: 1,
                stacked: true,
                title: {
                  display: true,
                  text: yLabel,
                  font: {
                    family: "Poppins",
                    weight: "bold",
                    size: "14px",
                  },
                },
                ticks: {
                  beginAtZero: true,
                  format: {
                    style: "percent"
                  }
                },
              },
            }
          }
        }
      />
    </>
  );
};

export default BarChart;
