import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import "../App.css";

import { AppBar, Button, Chip, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TARAHeader from "../data/tara-header-navbar.png";

const Navbar = ({signOut, user, header}) => {
  const [chartWidth, setChartWidth] = useState(0)
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleSiderbarDisplayClick = (e) => {
    const sidebarElement = ReactDOM.findDOMNode(document.querySelector(".mainContent .sidebarContent"))
    const chartsElement = ReactDOM.findDOMNode(document.querySelector(".mainContent .charts"))
    if(window.screen.width>1100 && chartsElement && !chartWidth) setChartWidth(chartsElement.clientWidth)

    if(sidebarElement.style.display === "none"){
      if(window.screen.width>1100 && chartsElement) chartsElement.style.width = chartWidth+"px"
      sidebarElement.style.display = "flex"
    }
    else{
      if(window.screen.width>1100 && chartsElement) chartsElement.style.width = "unset"
      sidebarElement.style.display = "none"
    } 
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar color="inherit" position="static" className="navbar">
      <Toolbar>
        {user ? 
          <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleSiderbarDisplayClick}
          >
            <MenuIcon />
          </IconButton>
          :
            <div className="signedOutNavMenuDiv">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="home-menu"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                className="signedOutNavMenu"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button color="inherit" sx={{textTransform: "unset", justifyContent: "start"}} to="/" component={Link}>
                    Login
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button color="inherit" sx={{textTransform: "unset", justifyContent: "start"}} to="/privacy-policy" component={Link}>
                    Privacy Policy
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button color="inherit" sx={{textTransform: "unset", justifyContent: "start"}} to="/contact-us" component={Link}>
                    Contact Us
                  </Button>
                </MenuItem>
              </Menu>
            </div>
        }
        <div className="navHeader">
          <IconButton component={Link} to="/" disableFocusRipple disableRipple sx={{padding: 0}}>
            <img alt="TARA-Header" height="28px" src={TARAHeader}/>
          </IconButton>
        </div>
        {user 
          ?
            <div style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1, gap: "0.5rem" }}>
              <Chip color="info" label={"Hi, "+user.attributes.email} variant="outlined" />
              <Button onClick={signOut} color="info" variant="contained">
                Sign Out
              </Button>
            </div>
          :
            <div style={{paddingLeft: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", flexGrow: 1}}>
              <h3>{header}</h3>
              <div className="signedOutNav">
                <Button color="info" sx={{mr: 1, textTransform: "unset"}} to="/" component={Link} onClick={handleCloseNavMenu}>
                  Login
                </Button>
                <Button color="info" sx={{mr: 1, textTransform: "unset"}} to="/privacy-policy" component={Link} onClick={handleCloseNavMenu}>
                  Privacy Policy
                </Button>
                <Button sx={{textTransform: "unset"}} color="info" to="/contact-us" component={Link} onClick={handleCloseNavMenu}>
                  Contact Us
                </Button>
              </div>
            </div>
        }
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
