import React from 'react'
import ReactDOM from 'react-dom';
import { Link, useLocation, matchPath } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

const SidebarListItem = ({ name, icon, path, newTab }) => {

  const { pathname } = useLocation();
  const selected = matchPath(path, pathname);

  const handleListItemClick = () => {
    if(window.screen.width<=920){
      ReactDOM.findDOMNode(document.querySelector(".mainContent .sidebarContent")).style.display = "none"
    }
  };

  return (
    <ListItemButton
      component={Link}
      to={path}
      selected={selected ? true : false}
      onClick={handleListItemClick}
      {...newTab && {target:"_blank", rel:'noopener noreferrer'}}
    >
      <ListItemIcon sx={{minWidth: "45px"}}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  )
}

export default SidebarListItem