import React, { useState } from 'react'
import { Alert, AlertTitle, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Snackbar } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

function DeleteStudent({ handleClose, studentData }) {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [deleteState, setDeleteState] = useState(false);

  const deleteStudent = async (testId, studId) => {

    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;
      const adminId = data.idToken.payload["custom:db_id"]

      try {
        const deleteResponse =  await axios.delete("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/students?testId="+testId+"&studId="+studId, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("Upload Response: ",deleteResponse.data)
        if(deleteResponse.data === "Deletion Successful!") {
          alert("Deletion Successful: \n1. Student entry has been deleted. \n2. Please ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.");
          setToastType(["success", "Deleted Student Entry Successfully!"])
          window.location.reload()
        }
        else if(deleteResponse.data === "Not Allowed to delete student entry when recordings exist in database."){
          alert("Deletion Aborted: \n1. Student recordings have already been uploaded from the TARA App. \n2. You will not be able to delete any entries. \n3. Please fill up the form by clicking on \"Report Issue\" from the Navigation Bar on the left, if you still wish to delete the student entry. We will get in touch with you to assist with the same.")
          setToastType(["error", "Deletion Aborted!"])
        }
        else{
          alert("Deletion Cancelled: \n"+deleteResponse.data)
          setToastType(["error", deleteResponse.data])
        }
        setToastOpen(true)
        setDeleteState(false)
      }
      catch (error) {
        console.log(error)
        setToastType(["error", "Entry not Deleted due to Error!"])
        setToastOpen(true)
        setDeleteState(false)
      }
    })
    .catch((err) => console.log(err));
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const handleDelete = () => {
    if(!studentData.test){
      alert("Test has not started yet")
      return
    }

    setDeleteState(true)
    deleteStudent(studentData.test.testId, studentData.studId)
  }

  return (
    <>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
        <WarningIcon color="error" sx={{fontSize: "1.25rem"}}/>
        <b>Delete Student Entry?</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className='deleteStudent' style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
          {(studentData.test ? studentData.test.status===0 : false) 
            ?
              <>
                <div className="deleteStudentDetails" style={{display: "flex", gap:"1rem", backgroundColor: "#F1F3F9", borderRadius: "10px", padding: "1rem"}}>
                  <div style={{display: "flex", flexDirection: "column", flex: "0.7", gap: "0.5rem"}}>
                    <h4>Student Basic Details:</h4>
                    <p>Student Name: {studentData.fullName}</p>
                    <p>Gender: {studentData.gender}</p>
                    <p>PEN No: {studentData.studId}</p>
                  </div>
                  <Divider orientation="vertical" flexItem/>
                  <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                    <h4>Class Details:</h4>
                    <p>Class: {studentData.std}</p>
                    <p>Section: {studentData.divn}</p>
                    <p>Roll No: {studentData.rollNo}</p>
                  </div>
                </div>
                <div>
                  <h4 style={{paddingBottom: "0.5rem"}}>Note:</h4>
                  {/* <List dense disablePadding sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                    <ListItem disableGutters>
                      <ListItemText primary={<p>1. If any recordings of above student have already been uploaded from the TARA App, you will not be able to delete the student.</p>}/>
                    </ListItem>
                  </List>
                  <h4 style={{padding: "0.5rem 0"}}>If you still wish to proceed,</h4> */}
                  <List dense disablePadding sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
                    <ListItem disableGutters>
                      <ListItemText primary={<p>1. By proceeding, you will be deleting the student entry from your school.</p>}/>
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText primary={<p>2. You will need to upload the student Excel sheet with all the corrected student details again.</p>}/>
                    </ListItem>
                  </List>
                </div>
              </>
            :
              <Alert severity="error">
                <AlertTitle><b>Student entry cannot be deleted after the Student Addition Phase.</b></AlertTitle>
              </Alert>
          }
        </div>
      </DialogContent>
      <DialogActions>
        {(studentData.test ? studentData.test.status===0 : false) 
        ? 
        <>
            <h4 style={{marginRight: "auto", paddingLeft: "1rem", fontSize: "14px"}}>Do you want to delete the student entry?</h4>
            <Button color="info" variant="contained" onClick={handleClose}>No, Cancel</Button>
            {deleteState 
              ?
              <Button color="error" variant="contained" disabled>Yes, Delete<CircularProgress size="1rem" color="inherit" sx={{marginLeft: "0.25rem"}}/></Button>
              :
              <Button color="error" variant="contained" onClick={handleDelete}>Yes, Delete</Button>
            }
          </>
        :
          <Button color="info" variant="contained" onClick={handleClose}>Close</Button>
        }
      </DialogActions>
    </>
  )
}

export default DeleteStudent