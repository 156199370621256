import React, { useState } from "react";
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { AlertTitle, Button, Chip, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Snackbar, Tab, Tabs, TextField } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Table from "../../TableComponent";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const Dropbox = ({adminInfo}) => {
  
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [studentForm, setStudentForm] = useState({penNo: ""});
  const [studentFormError, setStudentFormError] = useState({penNo: false});
  const [studentFormState, setStudentFormState] = useState(false);
  const [studentData, setStudentData] = useState("")
  const [importForm, setImportForm] = useState({"school": adminInfo.admin.schools[0].id, std: "", divn: "", rollNo: ""});
  const [importFormError, setImportFormError] = useState({rollNo: false});
  const [importFormState, setImportFormState] = useState(false);
  const [dropboxStudentsData, setDropboxStudentsData] = useState("");

  const orderBy = [];
  const columns = [
    {
      key: "fullName",
      headerName: "Student Name (Only Initials)",
      width: 80
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    {
      key: "std",
      headerName: "Class",
      width: 30
    },
    { 
      key: "divn", 
      headerName: "Section", 
      width: 30 
    },
    // { 
    //   key: "rollNo", 
    //   headerName: "Roll No", 
    //   width: 30 
    // },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    },
    { 
      key: "status", 
      headerName: "Remark", 
      width: 30 
    },
  ];

  const headers = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Student Name (Only Initials)", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    // { label: "Roll No", key: "rollNo" },
    { label: "Remark", key: "status" }
  ];

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue===1){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;

        const getDropboxStudentsData = async(adminId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/dropbox-export", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log(response.data)
            // adding full name by combining firstName and lastName
            const studentsData = response.data["students"].map(item => ({
              ...item, 
              "fullName": item["firstName"] + " " + item["lastName"],
              "status": item["status"] === 0 ? "Left School with TC" : "Long Absentee"
            }))
            setDropboxStudentsData(studentsData)
          }
          catch (error) {
            setToastType(["error", "Error in getting dropbox students!"])
            setToastOpen(true)
            console.log(error)
          }
        }
        getDropboxStudentsData(adminInfo.admin.adminId)
      })
      .catch((err) => console.log(err));
    }
  };

  const handleStudentFormChange = (field, value) => {
    // console.log(value)
    setStudentForm(prev => ({...prev, [field]: value}))
    if(field === "penNo"){
      // This checks that penNo contains only 11 or 12 digit numbers & checks for any whitespace character at the start of string
      if((/^(?!\s)(\d{11}|\d{12})$/).test(value)) setStudentFormError(prev => ({...prev, [field]: false}))   // \d is same as 0-9
      else setStudentFormError(prev => ({...prev, [field]: true}))
    }
  }

  const handleStudentForm = () => {
    if(!(/^(?!\s)(\d{11}|\d{12})$/).test(studentForm.penNo)) {
      setToastType(["warning", "Please fill all the required fields!"])
      setToastOpen(true)
      return
    }
    setStudentData("")
    setImportForm({"school": adminInfo.admin.schools[0].id, std: "", divn: "", rollNo: ""})
    setStudentFormState(true)

    const adminId = adminInfo.admin.adminId
    const penNo = studentForm.penNo
    
    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;

      try {
        const getResponse =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/dropbox-import?studId="+penNo, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("get Response: ",getReponse.data)
        setStudentData(getResponse.data)
        setStudentFormState(false)
      }
      catch (error) {
        if ([400, 405, 406].includes(error.response.status)) setToastType(["error", error.response.data.message])
        else{
          console.log(error)
          setToastType(["error", "Error in getting the entry!"])
        }
        setToastOpen(true)
        setStudentFormState(false)
      }
    })
    .catch((err) => console.log(err));
  }

  const handleImportFormChange = (field, value) => {
    // console.log(value)
    setImportForm(prev => ({...prev, [field]: value}))
    if(field === "rollNo"){
      // This checks that rollNo contains only numbers & checks for any whitespace character at the start of string
      // if(!(/^(?!\s)(\d{1,99})$/).test(value)) setImportFormError(prev => ({...prev, [field]: true}))   // \d is same as 0-9
      if(value>0 && value <= 99) setImportFormError(prev => ({...prev, [field]: false}))   
      else setImportFormError(prev => ({...prev, [field]: true}))
    }
  }

  const handleImportForm = () => {
    if(!(importForm.rollNo>0 && importForm.rollNo <= 99) || !(importForm.school) || !(importForm.std) || !(importForm.divn)) {
      setToastType(["warning", "Please fill all the required fields!"])
      setToastOpen(true)
      return
    }
    
    if(!adminInfo.test){
      setToastType(["warning", "Test not found!"])
      setToastOpen(true)
      return
    }

    const adminId = adminInfo.admin.adminId
    const testId = adminInfo.test.testId
    
    setImportFormState(true)
    
    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;

      try {
        const postResponse =  await axios.post("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/dropbox-import", 
          JSON.stringify({
            "testId": testId,
            "studId": studentData.studId,
            "schoolId": importForm.school,
            "std": importForm.std,
            "divn": importForm.divn,
            "rollNo": importForm.rollNo
          }), 
          {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("post Response: ",postResponse.data)
        alert("Dropbox Import Successful!: \nPlease ensure that teachers from your class logout and login again on the TARA Android App so that they can see the updated student list.");
        setToastType(["success", postResponse.data.message])
        setToastOpen(true)
        setStudentForm({penNo: ""})
        setImportForm({"school": adminInfo.admin.schools[0].id, std: "", divn: "", rollNo: ""})
        setStudentData("")
        setImportFormState(false)
      }
      catch (error) {
        if ([405, 406, 422].includes(error.response.status)) setToastType(["error", error.response.data.message])
        else{
          console.log(error)
          setToastType(["error", "Error in importing student entry!"])
        }
        setToastOpen(true)
        setImportFormState(false)
      }
    })
    .catch((err) => console.log(err));
  }

  return (
    <div className="dropbox" style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Dropbox</h2>
      <div className="tabs" style={{display: "flex"}}>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}}>
          <Tab label="Import" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          <Tab label="Student List" sx={{fontSize: "inherit", minHeight: "30px"}}/>
        </Tabs>
      </div>
      {tabValue===0 &&
        <>{(adminInfo.test ? adminInfo.test.status===1 : false) ?
          <>
            <div className="forms">
              <FormControl sx={{ m: 1, minWidth: 150}}>
                <TextField
                  error = {studentFormError.penNo} 
                  helperText = {studentFormError.penNo ? "PenNo must have 11 or 12 digits number." : false} 
                  autoFocus
                  required
                  // margin="dense"
                  id="studId"
                  name="Pen No"
                  label="Pen No"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={studentForm.penNo}
                  onChange={(e) => handleStudentFormChange("penNo", e.target.value)}
                />
              </FormControl>
              <Button 
                color="info" 
                variant="contained" 
                sx={{m: 1}} 
                disabled={studentFormError.penNo || studentFormState}
                onClick={handleStudentForm}
              >Search</Button>
            </div>
            {studentData 
            ?
              <>
                <div className="dropboxDetails" style={{display: "flex", gap:"1rem", backgroundColor: "#F1F3F9", borderRadius: "10px", padding: "1rem"}}>
                  <div style={{display: "flex", flexDirection: "column", flex: "0.7", gap: "0.5rem"}}>
                    <h4>Student Status: {studentData.dropbox ? 
                      <>
                        <Chip label="In Dropbox" color='success' sx={{mr: 1}}/> 
                        {studentData.status ? <Chip label="Long Absentee" color='warning'/> : <Chip label="Left School with TC" color='success'/>}
                      </>
                      : <Chip label="Not in Dropbox" color='error'/>
                    }</h4>
                    <h4>Student Basic Details:</h4>
                    <p>Student Name: {studentData.firstName} {studentData.lastName}</p>
                    <p>Gender: {studentData.gender === "M" ? "Male" : "Female"}</p>
                    <p>PEN No: {studentData.studId}</p>
                  </div>
                  <Divider orientation="vertical" flexItem/>
                  <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                    <h4>Current School Details:</h4>
                    <p>Class: {studentData.std}</p>
                    {/* <p>School Code: {studentData.schoolId.slice(2,6)}</p> */}
                    <p>School Name: {studentData.name} Shift {studentData.shift}</p>
                    <p>Region: {studentData.region}</p>
                  </div>
                </div>
                {studentData.dropbox 
                ?
                  <>
                    <div style={{display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "#F1F3F9", borderRadius: "10px", padding: "1rem"}}>
                      <h4>Import Student:</h4>
                      <div style={{display: "flex", flexWrap: "wrap" , gap: "1rem"}}>
                        <FormControl sx={{minWidth: 150 }} size="small">
                          <InputLabel id="schoolFilterLabel">School</InputLabel>
                          <Select
                            labelId="schoolFilterLabel"
                            id="schoolFilter"
                            value={importForm.school}
                            onChange={(e) => handleImportFormChange("school", e.target.value)}
                            autoWidth
                            label="School Name"
                          >
                            {/* <MenuItem value="">
                              <em>None</em>
                              </MenuItem> */}
                            {/* getting all unique divn values from data */}
                            { adminInfo &&
                              adminInfo.admin.schools.map((item, i) => {
                                return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                        <FormControl sx={{minWidth: 150}} size="small">
                          <InputLabel id="stdFilterLabel">Class</InputLabel>
                          <Select
                            labelId="stdFilterLabel"
                            id="stdFilter"
                            value={importForm.std}
                            onChange={(e) => handleImportFormChange("std", e.target.value)}
                            autoWidth
                            label="Class"
                          >
                            {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                            {Object.keys(studentData.adminClassDetails).includes(String(studentData.std)) && 
                              <MenuItem value={studentData.std} selected>{studentData.std}</MenuItem>}
                            {/* {(studentData.std !==8 && Object.keys(studentData.adminClassDetails).includes(String(studentData.std+1))) && 
                              <MenuItem value={studentData.std+1}>{studentData.std+1}</MenuItem>} */}
                            {/* {!(Object.keys(studentData.adminClassDetails).includes(String(studentData.std)) || Object.keys(studentData.adminClassDetails).includes(String(studentData.std+1))) && 
                              <MenuItem disabled>Class not available</MenuItem>} */}
                            {!(Object.keys(studentData.adminClassDetails).includes(String(studentData.std))) && 
                              <MenuItem disabled>Class not available</MenuItem>}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 150}} size="small">
                          <InputLabel id="divnFilterLabel">Section</InputLabel>
                          <Select
                            labelId="divnFilterLabel"
                            id="divnFilter"
                            value={importForm.divn}
                            onChange={(e) => handleImportFormChange("divn", e.target.value)}
                            autoWidth
                            label="Section"
                          >
                            {/* <MenuItem value="">
                              <em>None</em>
                              </MenuItem> */}
                            {importForm.std ? 
                              studentData.adminClassDetails[importForm.std].map((item, i) => {
                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                              })
                            :
                              <MenuItem disabled>Select Std First</MenuItem>
                            }  
                          </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 150}} size="small">
                          <TextField
                            error = {importFormError.rollNo} 
                            helperText = {importFormError.rollNo ? "Roll No must be between 1 and 99." : false} 
                            required
                            // margin="dense"
                            id="rollNo"
                            name="Roll No"
                            label="Roll No"
                            // fullWidth
                            variant="outlined"
                            size="small"
                            value={importForm.rollNo}
                            onChange={(e) => handleImportFormChange("rollNo", e.target.value)}
                          />
                        </FormControl>
                        <Button 
                          color="info" 
                          variant="contained" 
                          disabled={importFormState || importFormError.std || importFormError.divn || importFormError.rollNo} 
                          onClick={handleImportForm}
                        >Import</Button>
                      </div>
                    </div>
                    <MuiAlert severity="info" className="uploadInstructions">
                      <AlertTitle><b>To import a student <Chip label="In Dropbox" color='success'/>, first select the Class & Section and enter the roll number for the student. Then click on "Import".</b></AlertTitle>
                    </MuiAlert>
                  </>
                :
                  <MuiAlert severity="error" className="uploadInstructions">
                    <AlertTitle><b>Importing Student is not allowed when the "Student Status" is <Chip label="Not in Dropbox" color='error'/></b></AlertTitle>
                    <p>Please contact the school and ask them to put the student in their dropbox to import the student.</p>
                  </MuiAlert>
                }
              </>
            :
              <MuiAlert severity="info" className="uploadInstructions">
                <AlertTitle><b>Enter Pen No & click on Search to get the student details.</b></AlertTitle>
              </MuiAlert>
            }
          </>
        :
          <MuiAlert severity="error">
            <AlertTitle><b>Dropbox is only available during the Testing Phase.</b></AlertTitle>
          </MuiAlert>
        }</>
      }
      {tabValue === 1 &&
        <div>
          {dropboxStudentsData 
            ? 
            <div className="studentsContent">
              <MuiAlert severity="info" className="uploadInstructions">
                <AlertTitle><b>Students Added in Dropbox by your school but not yet accepted by any other school.</b></AlertTitle>
              </MuiAlert>
              <div className="tabs" style={{paddingTop: "0.5rem"}}>
                <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ placeSelf: "start" }}>
                  <CSVLink data={dropboxStudentsData} headers={headers} filename={"DropboxStudentsData.csv"}>
                    Download as CSV
                  </CSVLink>
                </Button>
              </div>
              <Divider />
              <div className="table">
                <Table
                  columns={columns}
                  tableData={dropboxStudentsData}
                  orderBy={orderBy}
                  // tableHeight="47vh" 
                />
              </div>
            </div>
          :
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "60vh"}}><CircularProgress color="info"/></div>
          }
        </div>
      }
    </div>
  );
};

export default Dropbox;
